import { jsx as _jsx } from "react/jsx-runtime";
import merge from 'lodash/merge';
import { useMemo } from 'react';
// @mui
import { alpha, ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
//
import { useSettingsContext } from './SettingsContext';
export default function ThemeColorPresets({ children }) {
    const outerTheme = useTheme();
    const { presetsColor } = useSettingsContext();
    const themeOptions = useMemo(() => ({
        palette: {
            primary: presetsColor,
        },
        customShadows: {
            primary: `0 8px 16px 0 ${alpha(presetsColor.main, 0.24)}`,
        },
    }), [presetsColor]);
    const theme = createTheme(merge(outerTheme, themeOptions));
    return _jsx(ThemeProvider, { theme: theme, children: children });
}
